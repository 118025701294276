
.oa-page {
    .com-title {
        //border-bottom: none;
        border-bottom: 1px solid #eaeff3;
        div.title-div {
            position: relative;
            h5 {
                border-bottom: none;
                color: #2b221b;
                font-size: 22px;
                font-weight: bold;
            }
        }
        div.title-div::after {
            content: '';
            position: absolute;
            width: 20px;
            height: 10px;
            background: url(../assets/img/oa/icon-triangle.png);
            background-size: 100% 100%;
            left: 50%;
            transform: translateX(-50%);
            bottom: -10px;
        }
        span {
            display: flex;
            align-items: center;
            color: #777777;
            img {
                width: 20px;
                height: 20px;
                margin-left: 2px;
            }
        }
    }
    .introduction-paper {
        position: relative;
        min-height: 615px;
        z-index: 1;
        .top-bg {
            width: 100%;
            height: 323px;
            background: #eff4f8;
            position: absolute;
            z-index: -1;
        }
        .introduction-wrap {
            height: 440px;

            padding-top: 50px;

            box-sizing: border-box;
            margin-bottom: 34px;

            .wid1200 {
                position: relative;

                .left-introduction {
                    position: absolute;
                    left: 0;
                    top: 0;

                    z-index: 2;
                    padding-left: 10px;
                    .border-left-wrap {
                        z-index: -10;
                        position: absolute;
                        left: 0;
                        width: 44px;
                        height: 350px;
                        background: #c30d23;
                        padding-left: 10px;
                        border-radius: 40px 0px 0px 0px;
                        box-sizing: border-box;
                    }
                    .wid690 {
                        z-index: 100;
                        width: 699px;
                        border-radius: 40px 0px 0px 0px;
                        height: 380px;
                        overflow: hidden;
                        box-sizing: border-box;
                        position: relative;
                    }
                    .wid690::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 6px;
                        height: 350px;
                        background: #c30d23;
                    }
                }

                //<div class="left-introduction">
                //    <div class="border-left-wrap">
                //        <div class="border-left"></div>
                //    </div>
                //    <div class="wid690">
                //        <top-banner :bannerData="imgBannerData"></top-banner>
                //    </div>
                .right-introduction {
                    float: right;
                    width: 552px;
                    h6 {
                        padding-top: 40px;
                        padding-bottom: 24px;
                        font-size: 24px;
                        color: #828282;
                        line-height: 22px;
                        padding-left: 94px;
                    }
                    h5 {
                        color: #292929;
                        font-size: 32px;
                        margin-bottom: 34px;
                        line-height: 32px;
                        padding-left: 94px;
                    }
                    div {
                        width: 552px;
                        height: 230px;
                        background: #ffffff;
                        box-shadow: 0px 8px 20px 0px rgba(116, 126, 134, 0.1);
                        border-radius: 0px 0px 20px 0px;
                        padding: 40px 18px 0 90px;
                        box-sizing: border-box;
                        p {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #828282;
                            line-height: 26px;
                        }
                        button {
                            margin-top: 18px;
                            float: right;
                            border: none;

                            height: 14px;
                            line-height: 14px;
                            padding: 0;
                            a {
                                //width: 49px;
                                display: flex;
                                font-size: 14px;
                                color: #777777;
                                background: #fff;
                                align-items: center;
                                img {
                                    width: 20px;
                                    height: 20px;
                                    margin-left: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .article-wrap {
            //padding: 10px 30px 0;
            //margin: 26px auto;
            box-sizing: border-box;
            //border: 1px solid #f5f5f5;
            //box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            /*.com-title {
                //border-bottom: none;
                border-bottom: 1px solid #EAEFF3;
                div {
                    position: relative;
                    h5 {
                        border-bottom: none;
                        color: #2B221B;
                        font-size: 22px;
                    }

                }
                div.title-div::after {
                    content: '';
                    position: absolute;
                    width: 20px;
                    height: 10px;
                    background: url(../assets/img/oa/icon-triangle.png);
                    background-size: 100% 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -10px;
                }
                span {
                    display: flex;
                    align-items: center;
                    color: #777777;
                    img {
                        width: 20px;
                        height: 20px;
                        margin-left: 3px;
                    }
                }
            }*/

            ul.com-article {
                margin-top: 25px; //17
                margin-bottom: 24px;
                display: flex;
                flex-wrap: wrap;
                //background: url(../assets/img/border1.png);
                //background-size: 1px 50%;
                //background-repeat: repeat-y;
                //background-position: 50% 0%;
                li {
                    width: 574px;
                    border-bottom: 1px dashed #eaeff3;
                    display: flex;
                    .is-lock {
                        margin-right: 10px;
                        padding-top: 15px;
                        div {
                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    .article-content {
                        display: block;
                        h5 {
                            margin-top: 15px;
                            line-height: 20px;
                            margin-bottom: 12px;
                            display: flex;
                            align-items: center;
                            width: 475px; //533px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            //img {
                            //    width: 16px;
                            //    height: 20px;
                            //    margin-right: 7px;
                            //}
                            p {
                                //width: 473px; //533px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-size: 16px;
                                color: #2b221b;
                                margin-right: 7px;
                            }
                            span {
                                height: 20px;
                                line-height: 20px;
                                padding: 0 9px;
                                background: #eaeff3;
                                border-radius: 0px 10px 10px 10px;
                                color: #828282;
                            }
                        }
                        .cursor-h5 {
                            cursor: pointer;
                        }
                        div {
                            display: flex;
                            margin-bottom: 19px;
                            p {
                                color: #828282;
                                font-size: 14px;
                                line-height: 15px;
                                margin-right: 26px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            p.author {
                                width: 174px;
                            }
                            p.name {
                                width: 180px;
                            }

                            p.time {
                            }
                        }
                    }
                }
                li:nth-child(2n + 1) {
                    margin-right: 52px;
                }
                li:nth-child(2n + 2) {
                    margin-left: 33px;
                    width: 536px;
                    h5 {
                        width: 536px;
                    }
                }
                li:nth-last-child(2) {
                    margin-bottom: 0;
                }
                li:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .oa-news-video-data {
        background: #f1f7fb;
        padding: 28px 0;
        .wid1200 {
            display: flex;
            .oa-news,
            .oa-data {
                width: 388px;
                background: #fff;
                margin-right: 24px;
                padding: 10px 16px 20px;
                ul {
                    padding-top: 20px;
                }

                li {
                    width: 356px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    box-sizing: border-box;
                    //margin-bottom: 18px;
                    p {
                        position: relative;
                        width: 356px;
                        font-size: 14px;
                        line-height: 44px;
                        padding-left: 26px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        box-sizing: border-box;
                        a {
                            color: #2b221b;
                            cursor: pointer;
                        }
                    }
                    p::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 20px;
                        width: 6px;
                        height: 6px;
                        line-height: 18px;
                        border-radius: 50%;
                        background: #c30d23;
                    }
                    span {
                        display: block;
                        font-size: 18px;
                        width: 112px;
                        text-align: left;
                        margin-left: 10px;
                    }
                }
            }
            .oa-video {
                width: 388px;
                background: #fff;
                margin-right: 24px;
                padding: 10px 16px 20px;
                .oa-video-wrap {
                    video {
                        width: 356px;
                        height: 203px;
                        margin-top: 30px;
                        cursor: pointer;
                    }
                    img.cover-img {
                        width: 356px;
                        height: 203px;
                        margin-top: 30px;
                        cursor: pointer;
                    }
                    h6 {
                        font-size: 14px;
                        color: #2b221b;
                        line-height: 27px;
                        margin-top: 9px;
                        cursor: pointer;
                    }
                    p.date {
                        margin-top: 9px;
                        color: #828282;
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                        img {
                            width: 19px;
                            margin-right: 6px;
                        }
                    }
                }
            }
            .oa-data {
                margin-right: 0;
            }
        }
    }
    //.top-banner-wrap {
    //    width: 100%;
    //    background: #cb2f3c;
    //    min-height: 300px;
    //}
    .oa-main {
        padding-top: 37px;
        .book-wrap {
            //padding: 10px 30px 0;
            //margin-bottom: 26px;
            box-sizing: border-box;
            //border: 1px solid #f5f5f5;
            //box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
            .com-book-wrap {
                ul.com-book {
                    position: relative;
                    margin-top: 40px;
                    z-index: 0;
                    display: flex;
                    //flex-wrap: wrap;
                    li {
                        margin-right: 24px;
                        width: 180px;
                        margin-bottom: 15px;
                        img {
                            width: 180px;
                            height: 260px;
                            margin-bottom: 75px;
                            cursor: pointer;
                            box-shadow: 0px 15px 18px -15px #4f4a4a;
                        }
                        p {
                            color: #0d1020;
                            font-size: 16px;
                            width: 180px;
                            line-height: 16px;
                            text-align: center;
                            cursor: pointer;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    li:last-child {
                        margin-right: 0;
                    }
                }
                ul.com-book::after {
                    content: '';
                    position: absolute;
                    width: 1380px;
                    height: 116px;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    z-index: -2;
                    background: url(../assets/img/oa/img-bookshelf.png);
                    background-size: 100% 100%;
                }
            }
        }
        .courseware-wrap {
            display: flex;
            margin-top: 43px;
            margin-bottom: 45px;
            .left-content {
                width: 928px;
                margin-right: 40px;
                ul {
                    padding-top: 16px;
                    li {
                        a {
                            width: 928px;
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            color: #2b221b;
                            line-height: 57px;
                            border-bottom: 1px dashed #eaeff3;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            cursor: pointer;
                            span {
                                background: #bd1a2d;
                                width: 26px;
                                height: 26px;
                                line-height: 26px;
                                color: #fff;
                                font-size: 10px;
                                text-align: center;
                                border-radius: 4px;
                                margin-right: 6px;
                            }
                            //img {
                            //    width: 30px;
                            //    margin-right: 11px;
                            //}
                        }
                    }
                }
            }
            .right-button {
                padding-top: 10px;
                width: 230px;
                height: 400px;
                button {
                    width: 240px;
                    height: 30px;
                    background: #fafbfc;
                    border: none;
                    border-radius: 4px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 14px;
                    }
                }
                .down-btn {
                    margin-bottom: 0;
                    margin-top: 10px;
                }
                .button-wrap {
                    width: 240px;
                    max-height: 320px;
                    overflow: hidden;
                    position: relative;
                    .button-ul {
                        transition: 1s;
                    }
                }
                ul {
                    li {
                        width: 240px;
                        height: 56px;
                        font-size: 20px;
                        color: #0d1020;
                        line-height: 56px;
                        margin-bottom: 10px;
                        text-align: center;
                        background: url(../assets/img/oa/bg-link-default.png);
                        background-size: 100% 100%;
                        cursor: pointer;
                        a {
                            display: block;
                            width: 240px;
                            height: 56px;
                            color: #0d1020;
                            line-height: 56px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            box-sizing: border-box;
                            padding: 0 10px;
                        }
                    }
                    li:hover {
                        color: #fff;
                        background: url(../assets/img/oa/bg-link-selected.png);
                        background-size: 100% 100%;
                        a {
                            color: #fff;
                        }
                    }
                }
            }
        }
        .visual-analysis-wrap {
            h6 {
                padding-left: 18px;
                color: #2b221b;
                font-size: 18px;
                font-weight: bold;
                position: relative;
                line-height: 18px;
                margin-top: 20px;
            }
            h6::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 6px;
                height: 18px;
                background: #c30d23;
            }
            .top-chart {
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
                margin-bottom: 20px;
                .left-resourceDisciplineDistributionMap,
                .right-word-cloud-chart {
                    width: 590px;
                    height: 390px;
                    //padding: 20px 20px 40px 20px;
                    border-radius: 4px;
                    border: 1px solid #eaeff3;
                    .right-word-cloud-chart-wrap {
                        overflow: hidden;
                        width: 590px;
                        height: 350px;
                    }
                }
            }
            .bot-chart {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                border: 1px solid #eaeff3;
                margin-bottom: 38px;
            }
        }

        .oa-platform-wrap {
            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: 40px;
                li {
                    width: 230px;
                    margin-right: 12px;
                    margin-bottom: 20px;
                    a {
                        display: block;
                        position: relative;
                        z-index: 0;
                        cursor: pointer;
                        img {
                            display: block;
                            width: 230px;
                            height: 140px;
                        }
                        h6 {
                            line-height: 40px;
                            background: #f5f7f9;
                            font-size: 20px;
                            font-weight: bold;
                            color: #2b221b;
                            text-align: center;
                            width: 230px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            box-sizing: border-box;
                            padding: 0 10px;
                        }
                        p {
                            position: absolute;
                            width: 230px;
                            height: 180px;
                            left: 0;
                            top: 0;

                            background: rgba(0, 0, 0, 0.7);
                            z-index: 2;
                            padding: 30px 25px 0 25px;
                            cursor: pointer;
                            display: none;
                            span {
                                font-size: 14px;
                                color: #fff;
                                line-height: 24px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 5; // 控制多行的行数
                                -webkit-box-orient: vertical;
                                box-sizing: border-box;
                            }
                        }
                    }
                    a:hover {
                        p {
                            display: block;
                        }
                    }
                }
                li:nth-child(5n + 5) {
                    margin-right: 0;
                }
            }
        }
    }
}

.pagination-wrap {
    display: flex;
    align-items: center;
    .total-page {
        font-size: 14px;
        color: #333 !important;
        margin-right: 4px;
    }
}
