
.header-oa-component {
    width: 100%;
    height: 400px;
    background: url(../assets/img/oa/OA-banner.png);
    background-size: 100% 100%;
    float: left;
    color: #fff;
    .header-oa-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title-logo {
            width: 937px;
            margin-top: 108px;
        }
        .search-input {
            width: 1016px;
            height: 96px;
            padding: 8px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            box-sizing: border-box;
            margin-top: 40px;
        }
        .search-border-wrap {
            .search-icon {
                font-size: 26px;
                line-height: 0.46rem;
                color: #fff;
                cursor: pointer;
            }
            .search-btn {
                padding: 0 52px;
                border-radius: 0%;
                float: right;
                color: #fff;
                font-weight: 700;
            }
        }
    }
}
